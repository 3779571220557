import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";
import store from "@/store";

export const updateCustomizationStatus = async (
  entityCustomizationId,
  status
) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  let selectedEntityCustomization =
    await fetchDataEntityCustomizationIdFromLocal(entityCustomizationId);
  if (selectedEntityCustomization) {
    await db.entity_customization.update(selectedEntityCustomization, {
      isActive: status,
    });
  }
};

//delete entity by id
export const deleteEntityCustomization = async (entityCustomizationId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  let selectedEntityCustomization =
    await fetchDataEntityCustomizationIdFromLocal(entityCustomizationId);
  if (selectedEntityCustomization) {
    await db.entity_customization.delete(selectedEntityCustomization);
  }
};

export const fetchDataEntityCustomizationIdFromLocal = async (
  entityCustomizationId
) => {
  const [selectedEntityCustomization] = await db.entity_customization
    .where("_id")
    .equals(entityCustomizationId)
    .primaryKeys();
  return selectedEntityCustomization;
};

//update single entity
export const updateEntityCustomizationData = async (
  entityCustomizationId,
  data
) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  if (data?.id) {
    delete data.id;
  }
  let selectedEntityCustomization =
    await fetchDataEntityCustomizationIdFromLocal(entityCustomizationId);
  if (selectedEntityCustomization) {
    await db.entity_customization.delete(selectedEntityCustomization);
    await addSingleCustomizationToLocal(data);
  } else {
    await addSingleCustomizationToLocal(data);
  }
};

export const addSingleCustomizationToLocal = async (templateData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  return db.entity_customization.add({
    ...templateData,
    ...{ createdAt: new Date().toISOString() },
  });
};

export const fetchAllEntityCustomizationsById = async (id) => {
  try {
    let entityCustomizations;
    entityCustomizations = await fetchCustomizationsByIdFromLocal(id);
    if (!entityCustomizations) {
      entityCustomizations = await fetchCustomizationsByIdFromDatabase(id);
    }
    return entityCustomizations.data;
  } catch (e) {
    console.log("fetchAllEntityCustomizationsById", e);
    return [];
  }
};

export const fetchCustomizationsByIdFromLocal = async (id) => {
  return await db.entity_customization.get({ _id: id });
};

export const fetchCustomizationsByIdFromDatabase = async (id) => {
  return postAPICall("GET", `/entity-customization/${id}`);
};

export const fetchActiveEntityCustomizationsByEntity = async (entityId) => {
  try {
    let entityCustomizations;
    entityCustomizations = await fetchCustomizationsByEntityFromLocal(entityId);
    let appUsingMode = store.getters["auth/getAppMode"];
    if (!entityCustomizations && !appUsingMode) {
      entityCustomizations = await fetchCustomizationsByEntityFromDatabase(
        entityId
      );
    }
    return entityCustomizations;
  } catch (e) {
    console.log("fetchActiveEntityCustomizationsByEntity", e);
    return [];
  }
};

export const fetchCustomizationsByEntityFromLocal = async (entityId) => {
  return await db.entity_customization.get({ entity_id: entityId });
};

export const fetchCustomizationsByEntityFromDatabase = async (entityId) => {
  const result = await postAPICall(
    "GET",
    `/entity-customization/entity/isActive/${entityId}`
  );
  return result.data;
};

export const fetchAllEntityCustomizationsByEntity = async (entityId) => {
  try {
    let entityCustomizations;
    entityCustomizations = await fetchAllCustomizationsByEntityFromLocal(
      entityId
    );
    if (!entityCustomizations.length) {
      entityCustomizations = await fetchAllCustomizationsByEntityFromDatabase(
        entityId
      );
    }
    return entityCustomizations;
  } catch (e) {
    console.log("fetchAllEntityCustomizationsByEntity", e);
    return [];
  }
};

export const fetchAllCustomizationsByEntityFromLocal = async (entityId) => {
  return await db.entity_customization
    .where("entity_id")
    .equals(entityId)
    .toArray();
};

export const fetchAllCustomizationsByEntityFromDatabase = async (entityId) => {
  const result = await postAPICall(
    "GET",
    `/entity-customization/entity_id/${entityId}`
  );
  return result.data;
};
